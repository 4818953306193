import { assert } from 'ts-essentials'

export type ProtectionStatusChange<ServerSideData = unknown> = {
  status: UpdatedProtectionStatus
  fromTimestamp: number
} & ServerSideData

/**
 * Protection statuses that may result from a status change
 */
export const updatedProtectionStatusValues = ['active', 'suspended', 'terminated'] as const
export type UpdatedProtectionStatus = (typeof updatedProtectionStatusValues)[number]

/**
 * All possible protection statuses
 */
const protectionStatusValues = ['not-started', ...updatedProtectionStatusValues] as const
export type ProtectionStatus = (typeof protectionStatusValues)[number]

export type ScheduledUpdates = {
  [Key in UpdatedProtectionStatus]: { scheduledAtTimestamp: number | null }
}

export type ProtectionStatusSchedule<ServerSideData = unknown> = {
  currentStatus: ProtectionStatus
  currentStatusCause: ProtectionStatusChange<ServerSideData> | null
  /**
   * Timestamp from which the currentStatus took effect
   */
  fromTimestamp: number
  /**
   * Timestamp at which the protection schedule was computed
   */
  computedAtTimestamp: number
  /**
   * if a future update is scheduled, there will be an updateTimestamp associated with it.
   * if no update is coming, scheduledStatusUpdates is null
   */
  scheduledStatusUpdates: ScheduledUpdates | null
}

/**
 * When the status is 'not-started', we do not have the fromTimestamp on the ProtectionHistory. We put then a 0 value
 * for fromTimestamp
 */
export type TimedProtectionStatus<ServerSideData = unknown> = {
  status: ProtectionStatus
  /**
   * The change that causes the current status
   */
  cause: ProtectionStatusChange<ServerSideData> | null
  /**
   * Timestamp from which the status took effect
   */
  fromTimestamp: number
}

function getCurrentStatus<ServerSideData>(
  protectionHistory: ProtectionStatusChange<ServerSideData>[],
  timestamp: number,
): TimedProtectionStatus<ServerSideData> {
  const sorted = [...protectionHistory].filter((statusUpdate) => statusUpdate.fromTimestamp <= timestamp)
  sorted.sort((a, b) => a.fromTimestamp - b.fromTimestamp)

  if (sorted.length === 0) {
    return {
      status: 'not-started',
      cause: null,
      fromTimestamp: 0,
    }
  }

  const lastItem = sorted[sorted.length - 1]
  assert(lastItem, 'lastItem should be defined')

  // we return the last update since the data is already filtered and sorted
  return {
    status: lastItem.status,
    cause: lastItem,
    fromTimestamp: lastItem.fromTimestamp,
  }
}

/**
 * Returns the protection status applicable at a specific time, give the protection history.
 * @param protectionHistory
 * @param currentTimestamp the time at which we want to compute the status, for example Date.now()
 */
export function getProtectionStatusAtTime<ServerSideData>(
  protectionHistory: ProtectionStatusChange<ServerSideData>[],
  currentTimestamp: number,
): ProtectionStatusSchedule<ServerSideData> {
  const {
    status: currentStatus,
    cause: currentStatusCause,
    fromTimestamp,
  } = getCurrentStatus(protectionHistory, currentTimestamp)

  // Get future scheduled updates
  const sorted = [...protectionHistory]
  sorted.sort((a, b) => a.fromTimestamp - b.fromTimestamp)

  const futureChanges = sorted.filter((statusChange) => statusChange.fromTimestamp > currentTimestamp)

  if (futureChanges.length === 0) {
    return {
      currentStatus,
      currentStatusCause,
      computedAtTimestamp: currentTimestamp,
      fromTimestamp,
      scheduledStatusUpdates: null,
    }
  }

  return {
    currentStatus,
    currentStatusCause,
    fromTimestamp,
    computedAtTimestamp: currentTimestamp,
    scheduledStatusUpdates: {
      active: {
        scheduledAtTimestamp:
          futureChanges.find((statusChange) => statusChange.status === 'active')?.fromTimestamp || null,
      },
      suspended: {
        scheduledAtTimestamp:
          futureChanges.find((statusChange) => statusChange.status === 'suspended')?.fromTimestamp || null,
      },
      terminated: {
        scheduledAtTimestamp:
          futureChanges.find((statusChange) => statusChange.status === 'terminated')?.fromTimestamp || null,
      },
    },
  }
}

/**
 * Get allowed statuses that the contract can transition to given its status on the given timestamps
 */
export function getAllowedNextStatuses(
  protectionHistory: ProtectionStatusChange[],
  timestamp: number,
  mutaSelected: boolean,
): ProtectionStatus[] {
  const { status: currentStatus } = getCurrentStatus(protectionHistory, timestamp)
  switch (currentStatus) {
    case 'not-started':
      return ['not-started', 'active', 'terminated']
    case 'active':
    case 'suspended':
      return ['active', 'suspended', 'terminated']
    case 'terminated':
      return mutaSelected ? ['terminated'] : ['active', 'suspended', 'terminated']
  }
}

export function isUpdatedProtectionStatus(value: string): value is UpdatedProtectionStatus {
  const allowedValues: string[] = [...updatedProtectionStatusValues]
  return allowedValues.includes(value)
}

export function isProtectionStatus(value: string): value is ProtectionStatus {
  const allowedValues: string[] = [...protectionStatusValues]
  return allowedValues.includes(value)
}

export function isTerminatedStatus(initialStatus: string, selectedStatus: UpdatedProtectionStatus | null): boolean {
  return !!(selectedStatus === 'terminated' || (initialStatus === 'terminated' && !selectedStatus))
}
